












import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  private headers = []
  private routes = {}
  private topActions = []

  public mounted(): void {
    this.headers = [
      {
        text: 'Number',
        value: 'number',
        filter: {
          focus: true,
        },
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'VAT',
        value: 'vat.name',
        filter: {
          disable: true,
        },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Economy/Accounts/New',
            params: { id: '0' },
          }
        },
      },
    ]
  }

  private get rowActions() {
    const actions: any = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Economy/Accounts/Edit',
            params: { id: item.id },
          }
        },
      },
    ]
    actions.push({ id: 'delete' })
    return actions
  }
}
